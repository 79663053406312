var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-multi" }),
        _vm._v(_vm._s(_vm.$t("LED Info")) + " ")
      ]),
      _vm.CUSTOMER !== "LCL"
        ? _c("figure", { staticClass: "imgBox mt-10" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/img-led-guide.jpg"),
                alt: "LED CONFIGURATION GUIDE"
              }
            })
          ])
        : _vm._e(),
      _vm.CUSTOMER === "LCL"
        ? _c("figure", { staticClass: "imgBox mt-10" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/img-led-guide-LCL.jpg"),
                alt: "LED CONFIGURATION GUIDE"
              }
            })
          ])
        : _vm._e(),
      _c(
        "v-data-table",
        {
          staticClass: "tbl-type01 mt-10",
          attrs: {
            "item-key": "id",
            headers: _vm.tableHeaders,
            items: _vm.tableItems,
            "hide-default-footer": true,
            "item-class": _vm.getRowClass
          },
          on: { "click:row": _vm.openLedPopup }
        },
        [
          _c("template", { slot: "no-data" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")])
          ])
        ],
        2
      ),
      _c("LedPopup", {
        attrs: { selectedItem: _vm.selectedItem, dialogPopup: _vm.dialogPopup },
        on: { reload: _vm.reload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }